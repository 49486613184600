const fuelhubsupplements = [
    {
        id: 1,
        productName: "ON Whey 2kg",
        productImage: require('../Assets/supplements/ON-Whey-2kg.png'),
        productBrand: "OPTIMUM NUTRITION (ON)"
    },

    {
        id: 2,
        productName: "ON Serious Mass 5kg",
        productImage: require('../Assets/supplements/ON-Serious-Mass-5kg.png'),
        productBrand: "OPTIMUM NUTRITION (ON)"
    },

    {
        id: 3,
        productName: "BSN Syntha 2kg ",
        productImage: require('../Assets/supplements/BSN-Syntha-2kg.png'),
        productBrand: "BSN"
    },

    {
        id: 4,
        productName: "Mutant mass 5lbs",
        productImage: require('../Assets/supplements/Mutant-mass-5lbs.png'),
        productBrand: "BSN"
    },

    {
        id: 5,
        productName: "Mutant mass 15lbs",
        productImage: require('../Assets/supplements/Mutant-mass-15lbs.png'),
        productBrand: "BSN"
    },

    {
        id: 6,
        productName: "Isopure",
        productImage: require('../Assets/supplements/Isopure.png'),
        productBrand: "Isopure"
    },

    {
        id:7,
        productName: "ON creatine",
        productImage: require('../Assets/supplements/ON-creatine.png'),
        productBrand: "OPTIMUM NUTRITION (ON)"
    },

    {id:8,
        productName: "Fast&up Bcaa",
        productImage: require('../Assets/supplements/Fast&up-Bcaa.png'),
        productBrand: "FAST&UP"
    },

    {id:9,
        productName: "Psychotic Preworkout",
        productImage: require('../Assets/supplements/Psychotic-Preworkout.png'),
        productBrand: "Insane Labz"
    },

    {id:10,
        productName: "C4 ripped",
        productImage: require('../Assets/supplements/C4-ripped.png'),
        productBrand: "cellucor C4 Ripped"
    },

    {id:11,
        productName: "IASP whey protein 5lb",
        productImage: require('../Assets/supplements/IASP-whey-protein-5lb.png'),
        productBrand: "IASP"
    },


    {id:13,
        productName: "MT PERFORMANCE MASS TECH EXTREME 2000 CHOCOLATE 3KG",
        productImage: require('../Assets/supplements/MT-PERFORMANCE-MASS-TECH-EXTREME-2000-CHOCOLATE-3KG.png'),
        productBrand: "Muscle Tech"
    },

    {id:14,
        productName: "MT HYDROXYCUT HARDCORE SUPER ELITE 100CT IND",
        productImage: require('../Assets/supplements/MT-HYDROXYCUT-HARDCORE-SUPER-ELITE-100CT-IND.png'),
        productBrand: "Muscle Tech"
    },

    {id:15,
        productName: "MT ESP 100% CREATINE UNFLAVORED 250GM",
        productImage: require('../Assets/supplements/MT-ESP-100-CREATINE-UNFLAVORED-250GM.png'),
        productBrand: "Muscle Tech"
    },

    {id:16,
        productName: "MT ESP 100% GLUTAMINE UNFLAVORED 250GM",
        productImage: require('../Assets/supplements/MT-ESP-100-GLUTAMINE-UNFLAVORED-250GM.png'),
        productBrand: "Muscle Tech"
    },

    {id:17,
        productName: "MT VAPOR X5 FRUIT PUNCH BLAST 270GM",
        productImage: require('../Assets/supplements/MT-VAPOR-X5-FRUIT-PUNCH-BLAST-270GM.png'),
        productBrand: "Muscle Tech"
    },

    {id:18,
        productName: "RONNIE COLEMAN L-Carnitine Liquid - 3000 mg 160Z",
        productImage: require('../Assets/supplements/RONNIE-COLEMAN-L-Carnitine-Liquid---3000-mg-160Z.png'),
        productBrand: "Ronnie Coleman"
    },

    {id:19,
        productName: "RC PRO-ANTIUM 5LB",
        productImage: require('../Assets/supplements/RC-PRO-ANTIUM-5LB.png'),
        productBrand: "Ronnie Coleman"
    },

    {id:20,
        productName: "MT Performance Nitro Tech Milk Chooclate 1.81kg",
        productImage: require('../Assets/supplements/MT-Performance-Nitro-Tech-Milk-Chooclate-1.81kg.png'),
        productBrand: "Muscle Tech"
    },

    {id:21,
        productName: "MT Performance Nitrotech Ripped Chooclate 1.81kg",
        productImage: require('../Assets/supplements/MT-Performance-Nitrotech-Ripped-Chooclate-1.81kg.png'),
        productBrand: "Muscle Tech"
    },

    {id:22,
        productName: "GNC PP 100% WHEY PROTEIN POWDER CHOCOLATE FUDGE 4LBS",
        productImage: require('../Assets/supplements/GNC-PP-100-WHEY-PROTEIN-POWDER-CHOCOLATE-FUDGE-4LBS.png'),
        productBrand: "GNC"
    },

    {id:23,
        productName: "GNC PP 100% WHEY PROTEIN POWDER VANILLA 1.81KG",
        productImage: require('../Assets/supplements/GNC-PP-100-WHEY-PROTEIN-POWDER-VANILLA-1.81KG.png'),
        productBrand: "GNC"
    },

    {id:24,
        productName: "GNC PP 100% WHEY PROTEIN POWDER STRAWBERRY 4LBS",
        productImage: require('../Assets/supplements/GNC-PP-100-WHEY-PROTEIN-POWDER-STRAWBERRY-4LBS.png'),
        productBrand: "GNC"
    },

    {id:25,
        productName: "GNC PP POWER PROTEIN POWDER CHOCO 4LBS",
        productImage: require('../Assets/supplements/GNC PP POWER PROTEIN POWDER CHOCO 4LBS.png'),
        productBrand: "GNC"
    },

    {id:26,
        productName: "GNC AMP ISOLATE ZERO CARB CHOCOLATE POWDER 4LBS",
        productImage: require('../Assets/supplements/GNC-AMP-ISOLATE-ZERO-CARB-CHOCOLATE-POWDER-4LBS.png'),
        productBrand: "GNC"
    },

    {id:27,
        productName: "GNC MASS XXX POWDER CHOCOLATE 2KG",
        productImage: require('../Assets/supplements/GNC-MASS-XXX-POWDER-CHOCOLATE-2KG.png'),
        productBrand: "GNC"
    },

    {id:28,
        productName: "GNC L-GLUTAMINE POWDER 250GM",
        productImage: require('../Assets/supplements/GNC-L-GLUTAMINE-POWDER-250GM.png'),
        productBrand: "GNC"
    },

    {id:29,
        productName: "GNC PRO PERFORMANCE ZMA TABLET 1X60",
        productImage: require('../Assets/supplements/GNC-PRO-PERFORMANCE-ZMA-TABLET-1X60.png'),
        productBrand: "GNC"
    },

    {id:30,
        productName: "GNC MILK THISTLE HP CAPS 1X90",
        productImage: require('../Assets/supplements/GNC-MILK-THISTLE-HP-CAPS-1X90.png'),
        productBrand: "GNC"
    },

    {id:31,
        productName: "GNC L-ARGININE 1000MG 1X90",
        productImage: require('../Assets/supplements/GNC-L-ARGININE-1000MG-1X90.png'),
        productBrand: "GNC"
    },

    {id:32,
        productName: "GNC TRIPLE STRENGTH FISH OIL SOFTGEL CAP 1X60",
        productImage: require('../Assets/supplements/GNC-TRIPLE-STRENGTH-FISH-OIL-SOFTGEL-CAP-1X60.png'),
        productBrand: "GNC"
    },

    {id:33,
        productName: "GNC CALCIUM CITRATE TAB 1000MG 1X120",
        productImage: require('../Assets/supplements/GNC-CALCIUM-CITRATE-TAB-1000MG-1X120.png'),
        productBrand: "GNC"
    },

    {id:34,
        productName: "GNC MEGA MEN ONE DAILY TABLETS 1X30",
        productImage: require('../Assets/supplements/GNC-MEGA-MEN-ONE-DAILY-TABLETS-1X30.png'),
        productBrand: "GNC"
    },

    {id:35,
        productName: "GNC MEGA MEN SPORTS TABLETS 1X60",
        productImage: require('../Assets/supplements/GNC-MEGA-MEN-SPORTS-TABLETS-1X60.png'),
        productBrand: "GNC"
    },

    {id:36,
        productName: "GNC LIVE WELL PLASTIC SHAKER (BLACK) IS",
        productImage: require('../Assets/supplements/GNC-LIVE-WELL-PLASTIC-SHAKER-(BLACK)-IS.png'),
        productBrand: "GNC"
    },




]


export default fuelhubsupplements;
