import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='Footer'>
            <main>

                <div className="footer_section section1">
                    <div className="logo">
                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Logo.png')} width='100%' /></div>
                    <p>We are best in fitness service and we provide you best of our
                        instructor all over the state</p>
                    <div className="social_links">
                        <ul>
                            <li><a href='https://www.facebook.com/people/muscle_warriors_/100063974388326/'><i class="fa-brands fa-facebook-f"></i></a>        </li>
                            <li> <a href='https://www.instagram.com/muscle_warriors_/' target='_blank'><i class="fa-brands fa-instagram"></i></a></li>
                            <li> <a href=''><i class="fa-brands fa-x-twitter"></i></a></li>
                            <li> <a href='https://youtube.com/@dineshnarayanan7908?si=zVTG52VRerjRWRES' target='_blank'><i class="fa-brands fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div className="footer_section section2">
                    <h6>Quick Links</h6>
                    <ul>
                        <li><Link to='/'>Home</Link> </li>
                        <li><Link to='/about-us'>About Us</Link> </li>
                        <li><Link to='/gallery'>Gallery</Link> </li>
                        <li><Link to='/online-training'>Online Training</Link> </li>
                        <li><Link to='/offline-training'>Offline Training</Link> </li>
                        <li><Link to='/contact-us'>Contact Us</Link> </li>

                    </ul>
                </div>


                <div className="footer_section section3">
                <h6>Working Hours</h6>
                <ul>
                        <li><div>Monday - Saturday </div>         5.30 Am - 11 Pm </li>
                        <li><div>Sunday</div> 6.30 am - 1 Pm</li>
                        <li><div>Public Holidays  </div>   6.30 am - 1 Pm </li>
                        <li><div>Closed</div>Kaanum Pongal & Diwali</li>

                    </ul>

                </div>
            </main>
            <div className="copyrights">
            Copyrights © 2023 musclewarriors.All Rights Reserved
            </div>
        </div>
    )
}
