import React from 'react'
import Header from '../Header/Header'
import './Gallery.css'
import { useEffect } from 'react';

export default function Gallery() {
  useEffect(() => {
    // Scroll to the top with an immediate behavior
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);
  return (
    <div className='Gallery'>

<Header/>

<main>
<div className="banner sec">
                    <h1>Our Gallery</h1>
                </div>
                <p className='banner_content'>Welcome to our <span>Muscle Warriors</span> Gallery, where you can get an exclusive sneak peek into our state-of-the-art fitness facility. We believe that a picture is worth a thousand words, and here, you'll find a visual representation of the fantastic amenities and equipment we offer to help you achieve your fitness goals.</p>

</main>

    </div>
  )
}
