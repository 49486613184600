import React, { useRef, useState,useEffect } from 'react'
import './Contact.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';


export default function Contact() {

    useEffect(() => {
        // Scroll to the top with an immediate behavior
        window.scrollTo({ top: 0, behavior: 'instant' });
      }, []);


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [err, setErr] = useState(false)
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [popup, setPopup] = useState(false)


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        if (name === '' || number === '' || email === '' || message === '') {
            setErr(true);
        } else {
            setErr(false);
            setIsSubmitting(true); // Set the submitting state to true

            emailjs
                .sendForm('service_8ubz4yc', 'template_k9w7fr6', form.current, 'RzLsLo7sLljXvRigI')
                .then((result) => {
                    console.log(result.text);
                    setIsSubmitting(false); // Set the submitting state back to false after successful submission
                    setPopup(true)
                    setName("")
                    setEmail("")
                    setNumber("")
                    setMessage("")
                })
                .catch((error) => {
                    console.log(error.text);
                    setIsSubmitting(false); // Set the submitting state back to false after an error
                });
        }
    };

    const sendEmailFranchise = (e) => {
        e.preventDefault();

        if (name === '' || number === '' || email === '' || message === '') {
            setErr(true);
        } else {
            setErr(false);
            setIsSubmitting(true); // Set the submitting state to true

            emailjs
                .sendForm('service_8ubz4yc', 'template_3qszy7r', form.current, 'RzLsLo7sLljXvRigI')
                .then((result) => {
                    console.log(result.text);
                    setIsSubmitting(false); // Set the submitting state back to false after successful submission
                    setPopup(true)
                    setName("")
                    setEmail("")
                    setNumber("")
                    setMessage("")
                })
                .catch((error) => {
                    console.log(error.text);
                    setIsSubmitting(false); // Set the submitting state back to false after an error
                });
        }
    };

    return (
        <div className='Contact'>

<Helmet>
      <title>Muscle Warriors | Contact Us</title>
        <meta name="description" content="Unleash your inner strength and conquer your fitness goals at Muscle Warrior Gym, a unisex fitness center dedicated to sculpting champions." />
        <meta name="keywords" content="Dinesh Narayanan,Body Transformation Chennai,Group Fitness Classes Chennai,Chennai Strength Training,Affordable Gym Chennai,Professional Trainers Chennai,Chennai Fitness Community,Chennai Wellness Center,Exercise Classes in Chennai,Chennai Weight Loss Center,Chennai Nutrition Coaching,Fitness Programs in Chennai,Chennai Gym Reviews,Best Health Club Chennai,Chennai Bodybuilding,Chennai CrossFit,Chennai Yoga Classes,Cardio Workouts in Chennai,Health and Fitness Chennai,Personal Training in Chennai,Weightlifting in Chennai,Muscle Warriors Unisex Gym,Muscle Warrior Gym Chennai,Dinesh Narayanan Gym,Best Workout Place in Chennai,Chennai Gym Membership,,Unisex Gym in Chennai,Muscle Warrior Unisex Gym,Best Gym in Chennai,Muscle Warriors,,Muscle Warrior,Gym near me,Gym in Chennai,Muscle Warrior Chennai,Chennai Fitness Center,Top Fitness Club Chennai,fitness, gym, workouts, health,Gym near me,Fitness center in Chennai,Best gym chennai,chennai fitness classes,Personal training chennai,Workout classes chennai,Women's gym near me,CrossFit gym chennai,Yoga classes chennai,Affordable gym chennai,24-hour gym chennaiBodybuilding gym chennai,muscle warriors gym chennai,muscle warriors fitness center,muscle warriors workout classes,muscle warriors,gym supplements,protien supplements" />
        <meta name="author" content="Rithish Abinav" />
      </Helmet>

            <Header />
            <main>

                {/* banner */}

                <div className="banner">
                    <h1>Get in Touch </h1>
                </div>

                <p className='banner_content'>We're excited to hear from you! Whether you have questions about our services, want to schedule a visit, or simply want to say hello, we're here to help. Feel free to reach out using the contact details provided below or use the convenient contact form to send us a message.</p>

                {/* contact badge */}
                <div className="contact_badges sec">

                    <div className="_badge">
                        <div className="icon">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/ci1.webp')} />  </div>
                        <h3>ADDRESS</h3>
                        <p>Muscle Warriors Gym, Ethiraj Swami Salai, Kodungaiyur (East), Moolakadai, Kodungaiyur, Chennai, Tamil Nadu 600118</p>

                    </div>

                    <div className="_badge">
                        <div className="icon">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/ci2.webp')} /> </div>
                        <h3>MAIL</h3>
                        <p>musclewarriors@gmail.com</p>

                    </div>

                    <div className="_badge">
                        <div className="icon">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/ci3.webp')} />  </div>
                        <h3>PHONE</h3>
                        <p>+91 96772 26826</p>

                    </div>


                </div>

                {/* enquiry us */}
                <div className="enq_us sec">
                    <h3 className='heading'>Enquiry <span>Us</span></h3>
                    <section>
                        <form className="left_section" ref={form} onSubmit={sendEmail}>
                            <div>
                                <label>Name</label>
                                <input type='text' value={name} onChange={(e) => setName(e.target.value)} name='from_name' placeholder='Enter Your Name'></input>
                            </div>
                            <div>
                                <label>Number</label>
                                <input type='number' value={number} onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 10) {  // Allowing up to 10 digits
                                        setNumber(inputValue);
                                    }
                                }} name='number' maxLength="10" placeholder='Enter Your Number'></input>
                            </div>
                            <div>
                                <label>Email</label>
                                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' placeholder='Enter Your Mail ID'></input>
                            </div>
                            <div>
                                <label>Message</label>
                                <textarea maxLength='200' value={message} onChange={(e) => setMessage(e.target.value)} name='message' placeholder='Type Your Message Here...'></textarea>
                            </div>
                            {err && <p className='error'>Please Fill All the Fields</p>}

                            <button type="submit" value="Send" className='red_btn'> {isSubmitting ? 'Sending' : 'Submit'} </button>
                        </form>
                        <div className="right_section">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.5832238671314!2d80.24619597605869!3d13.125568511482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ffbc54ccc1%3A0x746578b35467cad!2sMuscle%20Warriors%20Gym!5e0!3m2!1sen!2sin!4v1693295005447!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </section>
                </div>

                {/* franchise */}
                <div id="franchise"></div>
                <div className="franchise sec">
                    <h3 className='heading'>Franchise Opportunities with Muscle Warriors Gym</h3>
                    <p className='mainpara'>Are you ready to take charge of your fitness journey and make a significant impact in your community? Muscle Warriors Gym offers an exciting franchise opportunity that combines your passion for fitness with the chance to run your own successful business. Join our mission to transform lives, one rep at a time, and become a part of the Muscle Warriors family.
                        <br /><br />
                        The initial investment for a Muscle Warriors Gym franchise includes costs such as location build-out, equipment, initial marketing, and training. Our team will work closely with you to determine the exact investment required based on your chosen location and local market conditions.</p>

                    <section>
                        <div className="left_section">
                            <h4>Why Choose Muscle Warriors Gym Franchise ?</h4>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Proven Success
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Muscle Warriors Gym has a track record of success with a strong and loyal customer base. Our effective training programs, state-of-the-art equipment, and supportive environment make us a favorite among fitness enthusiasts.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Comprehensive Support
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            When you become a Muscle Warriors franchisee, you're not alone. We provide you with comprehensive training and ongoing support to ensure you have all the tools you need to run a successful gym. From operations and marketing to customer engagement, we've got you covered.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Recognizable Brand      </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Muscle Warriors Gym is a well-known and respected brand in the fitness industry. By joining our franchise network, you benefit from instant brand recognition and credibility, which can significantly boost your gym's reputation in your local market.      </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Diverse Revenue Streams     </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Our franchise model offers various revenue streams, including membership fees, personal training, group classes, merchandise sales, and more. This diversity helps you build a stable and profitable business.    </div>
                                    </div>


                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Community Impact   </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            As a Muscle Warriors franchise owner, you'll become an integral part of your community by helping individuals achieve their fitness goals and improve their overall well-being. You'll foster a supportive and motivating environment that keeps members coming back for more.  </div>


                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapssix" aria-expanded="false" aria-controls="collapssix">
                                            Marketing Expertise  </button>
                                    </h2>
                                    <div id="collapssix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Our marketing team provides franchisees with proven marketing strategies and materials to help you effectively reach and attract your target audience. From social media campaigns to local events, we'll help you get the word out about your gym.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                            Innovation and Upgrades  </button>
                                    </h2>
                                    <div id="collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            We're committed to staying at the forefront of fitness trends and technology. As a franchisee, you'll benefit from our continuous innovation, ensuring your gym remains competitive and appealing to your members.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                            Prime Location Assistance  </button>
                                    </h2>
                                    <div id="collapseeight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Muscle Warriors Gym franchisees receive dedicated support in finding and securing the ideal location for their gym. Our team of experts will analyze local demographics and market trends to help you choose a location with high growth potential. This assistance ensures that your franchise is strategically positioned for success right from the start.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        Proven Business Model  </button>
                                    </h2>
                                    <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        When you invest in a Muscle Warriors Gym franchise, you're investing in a proven business model that has been refined over the years. Our well-established systems and processes cover every aspect of gym operations, from member onboarding to facility management. This proven approach minimizes the risk of trial and error, allowing you to focus on delivering exceptional fitness experiences to your members while maximizing your profitability.                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form className="right_section" ref={form} onSubmit={sendEmailFranchise}>
                            <h4>Contact for Franchise</h4>
                            <div>
                                <label>Name</label>
                                <input type='text' value={name} onChange={(e) => setName(e.target.value)} name='from_name' placeholder='Enter Your Name'></input>
                            </div>
                            <div>
                                <label>Number</label>
                                <input type='number' value={number} onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 10) {  // Allowing up to 10 digits
                                        setNumber(inputValue);
                                    }
                                }} name='number' maxLength="10" placeholder='Enter Your Number'></input>
                            </div>
                            <div>
                                <label>Email</label>
                                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' placeholder='Enter Your Mail ID'></input>
                            </div>
                            <div>
                                <label>Message</label>
                                <textarea maxLength='200' value={message} onChange={(e) => setMessage(e.target.value)} name='message' placeholder='Type Your Message Here...'></textarea>
                            </div>
                            {err && <p className='error'>Please Fill All the Fields</p>}

                            <button type="submit" value="Send" className='red_btn'> {isSubmitting ? 'Sending' : 'Submit'} </button>
                        </form>
                    </section>


                </div>

            </main>
            {
                popup &&

                <div className="popup">
                    <div className="dummy" onClick={() => setPopup(false)}></div>
                    <div className='alert'>
                        <p>Thank you for your enquiry. We will get in touch with you shortly !</p>
                        <button className='red_btn' onClick={() => setPopup(false)}>Ok</button>
                    </div>
                </div>
            }
<Footer/>
        </div>
    )
}
