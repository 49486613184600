import React, { useState } from 'react';
import { useParams,useNavigate  } from 'react-router-dom';
import '../Supplement/Supplement.css'
import fuelhubsupplements from '../SupplemetArray/Supllementarray'; // Import your supplement data
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';



export default function SupplementView() {


 

  const { brand } = useParams(); // Access the brand parameter from the URL
  const navigate = useNavigate();

  // Filter products based on the selected brand
  const [brandProducts, setBrandProducts] = useState(
    fuelhubsupplements.filter((item) => item.productBrand === brand)
  );
  console.log(brandProducts);

  const [selectedBrand, setSelectedBrand] = useState(''); // State for the selected brand

  const handleBrandChange = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === 'select product') {
      // Reset the product list when the default option is selecte
   
      setBrandProducts(fuelhubsupplements.filter((item) => item.productBrand === brand));

    } else {
      // Set the selected brand based on the option chosen
     

      setBrandProducts(fuelhubsupplements.filter((item) => item.productBrand === selectedOption));

    }
  };


  return (
    <div className="Supplement">
       <Helmet>
  <title>Fuel Hub | Your Source for Quality Supplements</title>
  <meta name="description" content="Fuel your fitness journey with the best supplements at Fuel Hub. We provide high-quality products and expert guidance to help you achieve your fitness goals." />
  <meta name="keywords" content="Fuel Hub, Supplements, Protein, Vitamins, Minerals, Workout Supplements, Fitness, Wellness, Health, Nutritional Supplements, Weight Management, Muscle Building, Exercise, Gym, Bodybuilding, Nutritional Guidance, Workout Plans, Sports Nutrition, Health Supplements, Dietary Supplements, Fitness Supplements, Wellness Supplements" />
  <meta name="author" content="Rithish Abinav" />
</Helmet>

<Header />
<main> <div className="banner sec">
          <h1>Fuel Hub</h1>
        </div>

        <p className="banner_content">
          Fuel your fitness journey with Fuel Hub supplements at{' '}
          <span>Muscle Warriors </span>gym. Our high-quality products, expert
          guidance, and success stories will help you achieve your fitness
          goals. Join our community and experience the ultimate transformation.
        </p>

      {/* Select options */}
      <div className="bar">
        <select onChange={handleBrandChange}>
          <option value="select product" disabled>
            select product
          </option>

          <option value="OPTIMUM NUTRITION (ON)">
            OPTIMUM NUTRITION (ON)
          </option>
          <option value="BSN">BSN</option>
          <option value="cellucor C4 Ripped">cellucor C4 Ripped</option>
          <option value="FAST&UP">FAST&UP</option>
          <option value="GNC">GNC</option>
          <option value="IASP">IASP</option>
          <option value="Insane Labz">Insane Labz</option>
          <option value="Isopure">Isopure</option>
          <option value="Ronnie Coleman">Ronnie Coleman</option>
          <option value="Muscle Tech">Muscle Tech</option>
        </select>
      </div>

      <div className="products">
        {/* Display products based on the selected brand */}
        {brandProducts
          .filter((item) => selectedBrand === '' || item.productName === selectedBrand)
          .map((item) => (
            <div className="_badge" key={item.id}>
              <img src={item.productImage} alt={item.productName} />
              <h3>{item.productName}</h3>
            </div>
          ))}
      </div>
      </main>
      <Footer/>
    </div>
  );
}
