import React from 'react'
import './OnlineTraining.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';


export default function OnlineTraining() {

  useEffect(() => {
    // Scroll to the top with an immediate behavior
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);


  return (
    <div className='OnlineTraining'>

<Helmet>
      <title>Muscle Warriors | Online Training</title>
        <meta name="description" content="Unleash your inner strength and conquer your fitness goals at Muscle Warrior Gym, a unisex fitness center dedicated to sculpting champions." />
        <meta name="keywords" content="Dinesh Narayanan,Body Transformation Chennai,Group Fitness Classes Chennai,Chennai Strength Training,Affordable Gym Chennai,Professional Trainers Chennai,Chennai Fitness Community,Chennai Wellness Center,Exercise Classes in Chennai,Chennai Weight Loss Center,Chennai Nutrition Coaching,Fitness Programs in Chennai,Chennai Gym Reviews,Best Health Club Chennai,Chennai Bodybuilding,Chennai CrossFit,Chennai Yoga Classes,Cardio Workouts in Chennai,Health and Fitness Chennai,Personal Training in Chennai,Weightlifting in Chennai,Muscle Warriors Unisex Gym,Muscle Warrior Gym Chennai,Dinesh Narayanan Gym,Best Workout Place in Chennai,Chennai Gym Membership,,Unisex Gym in Chennai,Muscle Warrior Unisex Gym,Best Gym in Chennai,Muscle Warriors,,Muscle Warrior,Gym near me,Gym in Chennai,Muscle Warrior Chennai,Chennai Fitness Center,Top Fitness Club Chennai,fitness, gym, workouts, health,Gym near me,Fitness center in Chennai,Best gym chennai,chennai fitness classes,Personal training chennai,Workout classes chennai,Women's gym near me,CrossFit gym chennai,Yoga classes chennai,Affordable gym chennai,24-hour gym chennaiBodybuilding gym chennai,muscle warriors gym chennai,muscle warriors fitness center,muscle warriors workout classes,muscle warriors,gym supplements,protien supplements" />
        <meta name="author" content="Rithish Abinav" />
      </Helmet>

      <Header />

      <main>

        {/* banner */}

        <div className="banner">
          <h1>Online Training Programs</h1>
        </div>

        <p className='banner_content'>Welcome to our Online Training Programs at <span>Muscle Warriors!</span> We understand that your fitness journey might not always align with visiting our physical gym. That's why we're thrilled to offer you the opportunity to achieve your fitness goals from the comfort of your own space.</p>


        {/* Why Choose Our Online Training? */}
        <div className="why_choose sec">
          <h3 className="heading">Why Choose Our<span> Online</span> Training?</h3>

          <div className="_badges">

            <div className="_badge">
              <div className="icon">
                <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/oti1.webp')}></img>
              </div>
              <h4>FLEXIBILITY</h4>
              <p>Life can be busy, but that shouldn't hinder your fitness progress. Our online training programs give you the flexibility to work out whenever and wherever suits you best.</p>
            </div>

            <div className="_badge">
              <div className="icon">
                <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/oti2.webp')}></img>
              </div>
              <h4>CUSTOMIZED WORKOUT</h4>
              <p>Our experienced trainers curate personalized workout plans based on your fitness level, goals, and equipment availability. Whether you're a beginner or an experienced athlete, we have a plan for you.</p>
            </div>

            <div className="_badge">
              <div className="icon">
                <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/oti3.webp')}></img>
              </div>
              <h4>ACCOUNTABILITY</h4>
              <p>We believe in the power of accountability. Our online platform allows you to track your progress, set goals, and connect with our trainers for guidance and support.</p>
            </div>

          </div>

        </div>

        {/* How It Works ? */}
        <div className="how_works sec">
          <h3 className="heading">How It Works ?</h3>

          <section>
            <div className="left_section">

              <div>
                <p><span>Choose Your Program</span> : Explore our range of online training programs, from cardio and strength training to yoga and mindfulness. Select the program that aligns with your goals.</p>
              </div>

              <div>
                <p><span>Personalized Plan</span> : After signing up, you'll receive a questionnaire to help us understand your fitness background and aspirations. Our trainers will then create a tailored plan just for you.</p>
              </div>

              <div>
                <p><span>Access Anytime</span> : Your customized workouts, exercise demos, and nutrition tips are accessible through our user-friendly online platform. Log in whenever it suits you.</p>
              </div>

              <div>
                <p><span>Connect with Trainers</span> : Stay in touch with our trainers through chat, video calls, and progress tracking. We're here to provide guidance, answer questions, and celebrate your successes.</p>
              </div>

            </div>
            <div className="right_section">

<div>
<iframe width="560" height="400" src="https://www.youtube.com/embed/0DmHCRc166Q?si=o3oHC5VC0TeMM8fO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>


            </div>
          </section>

        </div>

        {/* Our Sessions */}
        <div className="our_sessions sec">
        <h3 className="heading">Our Sessions</h3>
        
        <div className="_badges">
          <div className="_badge">
            <video controls src={require('../Assets/Session/clip 1.mp4')} width='100%' />
          </div>

          <div className="_badge">
          <video controls src={require('../Assets/Session/clip 2.mp4')} width='100%' />
       </div>

          <div className="_badge">
          <video controls src={require('../Assets/Session/clip 3.mp4')} width='100%' />
      </div>

          <div className="_badge">
          <video controls src={require('../Assets/Session/clip 4.mp4')} width='100%' />
         </div>

          <div className="_badge">
          <video controls src={require('../Assets/Session/clip 5.mp4')} width='100%' />
         </div>

          <div className="_badge">
          <video controls src={require('../Assets/Session/clip.mp4')} width='100%' />
          </div>

        </div>

        </div>

       {/* train with dinesh narayanan */}
       <div className="twdn sec ">
                    <h3 className="heading">Train with <span>Dinesh Narayanan</span> vis Muscle Warriors App</h3>
                    <p>Experience personalized fitness training like never before with Dinesh Narayanan, a renowned fitness expert. Our Muscle Warriors app brings Dinesh's expertise and guidance directly to your fingertips, empowering you to achieve your fitness goals from anywhere.</p>
                    <section>
                        <div className="left ">
                            <div>
                                <div>
                                   

                                    <ul>
                                        <li>Customized Diet plan with calories tracker</li>
                                        <li>Customized workout plan (with reference video)</li>
                                        <li>Supplement Guidance</li>
                                        <li>Progress Tracker</li>
                                        <li>Weekly Check-In</li>
                                        <li>Call/Text Support</li>
                                        <li>Contest Prep</li>
                                    </ul>
                                    <p className='note'>Plan available on Monthly & Quarterly option.</p>
                                </div>
                                <button className='enquire_btn'>Enquire Now</button>
                            </div>

                          

                        </div>

                        <div className="right"></div>
                    </section>
                </div>
       
        

      </main>

      <Footer/>

    </div>
  )
}
