import React from 'react'
import './Preloader.css'

export default function Preloader() {
    return (


        <div className='Preloader'>
            <div>
                <svg className='top' width="99" height="44" viewBox="0 0 99 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="96.8" height="1.76" fill="#EB1C26" />
                    <rect x="96.8008" y="42.2402" width="42.24" height="1.76" transform="rotate(-90 96.8008 42.2402)" fill="#EB1C26" />
                    <rect y="42.2402" width="42.24" height="1.76" transform="rotate(-90 0 42.2402)" fill="#EB1C26" />
                    <rect y="42.2402" width="15.84" height="1.76" fill="#EB1C26" />
                    <rect x="80.9609" y="42.2402" width="17.6" height="1.76" fill="#EB1C26" />
                    <rect x="36.9609" y="42.2402" width="24.64" height="1.76" fill="#EB1C26" />
                    <rect x="15.8398" y="44" width="26.4" height="1.76" transform="rotate(-90 15.8398 44)" fill="#EB1C26" />
                    <rect x="15.8398" y="17.6001" width="22.88" height="1.76" fill="#EB1C26" />
                    <rect x="59.8398" y="17.6001" width="22.88" height="1.76" fill="#EB1C26" />
                    <rect x="36.9609" y="42.2402" width="22.88" height="1.76" transform="rotate(-90 36.9609 42.2402)" fill="#EB1C26" />
                    <rect x="59.8398" y="42.2402" width="22.88" height="1.76" transform="rotate(-90 59.8398 42.2402)" fill="#EB1C26" />
                    <rect x="80.9609" y="42.2402" width="22.88" height="1.76" transform="rotate(-90 80.9609 42.2402)" fill="#EB1C26" />
                    <path d="M98.5 0V43H81.5V18.5H61.5V43H38V18.5H18V43H1V0H98.5Z" fill="#EB1C26" />
                </svg>



                <svg className='bottom' width="99" height="44" viewBox="0 0 99 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="96.8" height="1.76" transform="matrix(1 0 0 -1 0 44)" fill="#EB1C26" />
                    <rect width="42.24" height="1.76" transform="matrix(0 1 1 0 96.8008 1.75977)" fill="#EB1C26" />
                    <rect width="42.24" height="1.76" transform="matrix(0 1 1 0 0 1.75977)" fill="#EB1C26" />
                    <rect width="15.84" height="1.76" transform="matrix(1 0 0 -1 0 1.75977)" fill="#EB1C26" />
                    <rect width="17.6" height="1.76" transform="matrix(1 0 0 -1 80.9609 1.75977)" fill="#EB1C26" />
                    <rect width="24.64" height="1.76" transform="matrix(1 0 0 -1 36.9609 1.75977)" fill="#EB1C26" />
                    <rect width="26.4" height="1.76" transform="matrix(0 1 1 0 15.8398 0)" fill="#EB1C26" />
                    <rect width="22.88" height="1.76" transform="matrix(1 0 0 -1 15.8398 26.3999)" fill="#EB1C26" />
                    <rect width="22.88" height="1.76" transform="matrix(1 0 0 -1 59.8398 26.3999)" fill="#EB1C26" />
                    <rect width="22.88" height="1.76" transform="matrix(0 1 1 0 36.9609 1.75977)" fill="#EB1C26" />
                    <rect width="22.88" height="1.76" transform="matrix(0 1 1 0 59.8398 1.75977)" fill="#EB1C26" />
                    <rect width="22.88" height="1.76" transform="matrix(0 1 1 0 80.9609 1.75977)" fill="#EB1C26" />
                    <path d="M98.5 44V1H81.5V25.5H61.5V1H38V25.5H18V1H1V44H98.5Z" fill="#EB1C26" />
                </svg>

            </div>
        </div>
    )
}
