import React, { useState } from 'react';
import Header from '../Header/Header';
import './Supplement.css';
import { Link } from 'react-router-dom';
import fuelhubsupplements from '../SupplemetArray/Supllementarray';
import Footer from '../Footer/Footer';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';


export default function Supplement() {
  useEffect(() => {
    // Scroll to the top with an immediate behavior
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);



  const [selproduct, setSelproduct] = useState(fuelhubsupplements);
  const [toggle, setToggle] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');

  const BrandClick = (brand) => {
    const brandProducts = fuelhubsupplements.filter((item) => item.productBrand === brand);
    setSelproduct(brandProducts);
    setToggle(true);
    setSelectedBrand(brand);
  };
  

  const handleBrandChange = (event) => {
    const selectedOption = event.target.value;
  
    if (selectedOption === 'select product') {
      // Reset the products to the original list when the default option is selected
      setSelproduct(fuelhubsupplements);
      setToggle(false); // Hide the products section
      setSelectedBrand(''); // Clear selected brand
    } else {
      // Filter products based on the selected brand
      const brandProducts = fuelhubsupplements.filter((item) => item.productBrand === selectedOption);
      setSelproduct(brandProducts);
      setToggle(true);
      setSelectedBrand(selectedOption);
    }
  };
  

  return (
    <div className="Supplement">
     <Helmet>
  <title>Fuel Hub | Your Source for Quality Supplements</title>
  <meta name="description" content="Fuel your fitness journey with the best supplements at Fuel Hub. We provide high-quality products and expert guidance to help you achieve your fitness goals." />
  <meta name="keywords" content="Fuel Hub, Supplements, Protein, Vitamins, Minerals, Workout Supplements, Fitness, Wellness, Health, Nutritional Supplements, Weight Management, Muscle Building, Exercise, Gym, Bodybuilding, Nutritional Guidance, Workout Plans, Sports Nutrition, Health Supplements, Dietary Supplements, Fitness Supplements, Wellness Supplements" />
  <meta name="author" content="Rithish Abinav" />
</Helmet>


      <Header />

      <main>
        <div className="banner sec">
          <h1>Fuel Hub</h1>
        </div>

        <p className="banner_content">
          Fuel your fitness journey with Fuel Hub supplements at{' '}
          <span>Muscle Warriors </span>gym. Our high-quality products, expert
          guidance, and success stories will help you achieve your fitness
          goals. Join our community and experience the ultimate transformation.
        </p>

        {toggle ? (
          <div>
            <div className="bar">
              <select
                onChange={handleBrandChange}
                value={selectedBrand}
              >
                <option value="select product" disabled>
                  select product
                </option>

                <option value="OPTIMUM NUTRITION (ON)">
                  OPTIMUM NUTRITION (ON)
                </option>
                <option value="BSN">BSN</option>
                <option value="cellucor C4 Ripped">cellucor C4 Ripped</option>
                <option value="FAST&UP">FAST&UP</option>
                <option value="GNC">GNC</option>
                <option value="IASP">IASP</option>
                <option value="Insane Labz">Insane Labz</option>
                <option value="Isopure">Isopure</option>
                <option value="Ronnie Coleman">Ronnie Coleman</option>
                <option value="Muscle Tech">Muscle Tech</option>
              </select>
            </div>

            <div className="products">
              {selproduct.map((item) => (
                <div className="_badge" key={item.id}>
                  <img src={item.productImage} alt={item.productName} />
                  <h3>{item.productName}</h3>
                </div>
              ))}
            </div>
          </div>
        ) : (
         <section className="brands">
            <Link to='/supplement-view/OPTIMUM NUTRITION (ON)'
              className="_badge"
              onClick={() => BrandClick("OPTIMUM NUTRITION (ON)")}
            >
              <img src={require('../Assets/brand logos/on.jpg')} />
            </Link>
            <Link to='/supplement-view/BSN' className="_badge" onClick={() => BrandClick("BSN")}>
              <img src={require('../Assets/brand logos/bsn.jpg')} />
            </Link>
            <Link to='/supplement-view/cellucor C4 Ripped'
              className="_badge"
              onClick={() => BrandClick("cellucor C4 Ripped")}
            >
              <img src={require('../Assets/brand logos/c4.jpg')} />
            </Link>
            <Link to='/supplement-view/FAST&UP' className="_badge" onClick={() => BrandClick("FAST&UP")}>
              <img src={require('../Assets/brand logos/fastup.jpg')} />
            </Link>
            <Link to='/supplement-view/GNC' className="_badge" onClick={() => BrandClick("GNC")}>
              <img src={require('../Assets/brand logos/gnc.jpg')} />
            </Link>
            <Link to='/supplement-view/IASP' className="_badge" onClick={() => BrandClick("IASP")}>
              <img src={require('../Assets/brand logos/iasp.jpg')} />
            </Link>
            <Link to='/supplement-view/Insane Labz' className="_badge" onClick={() => BrandClick("Insane Labz")}>
              <img src={require('../Assets/brand logos/insane lab.jpg')} />
            </Link>
            <Link to='/supplement-view/Isopure' className="_badge" onClick={() => BrandClick("Isopure")}>
              <img src={require('../Assets/brand logos/isopure.jpg')} />
            </Link>
            <Link to='/supplement-view/Ronnie Coleman' className="_badge" onClick={() => BrandClick("Ronnie Coleman")}>
              <img src={require('../Assets/brand logos/rc.jpg')} />
            </Link>
            <Link  to='/supplement-view/Muscle Tech' className="_badge" onClick={() => BrandClick("Muscle Tech")}>
              <img src={require('../Assets/brand logos/mt.jpg')} />
            </Link>
          </section>
        )}











      </main>

      <Footer />
    </div>
  );
}
