import React, { useState } from "react";
import {  Link } from "react-router-dom";
import './Header.css';


function App() {
  const [isactive, setIsActive] = useState(false);
  const path = window.location.pathname;

  const navToggle = () => {
    setIsActive(!isactive);
  };

  return (
   
      <div className='Header' id='top'>
        <Link to='/' className="logo">
          <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Logo.png')} width='100%' />
        </Link>

        <div className="mobile_menu" onClick={navToggle}>
          {isactive ? <i className="fa-solid fa-xmark"></i>
            :
            <i className="fa-solid fa-bars"></i>
          }
        </div>

        <nav className={isactive ? "showNav" : ""}>
          <ul>
            <li> <Link to='/' className={path === '/' ? "activeNav" : ''}>Home</Link> </li>
            <li> <Link to='/about-us' className={path === '/about-us' ? "activeNav" : ''}>About Us</Link> </li>
            <li className='training_link'> 
              <Link to='/offline-training' className={path === '/offline-training' || path === '/online-training' ? "activeNav" : ''}>Training <i className="fa-solid fa-caret-down"></i></Link>
              <ul>
                <li> <Link to='/offline-training'>Offline Training</Link> </li>
                <li> <Link to='/online-training'>Online Training</Link> </li>
              </ul> 
            </li>
            <li> <Link to='/gallery' className={path === '/gallery' ? "activeNav" : ''}>Gallery</Link> </li>
            <li className="fuelhub_navlink"> <Link to='/fuelhub' ><span>Fuel</span> Hub</Link> </li>
            <li> 
              <Link to='/contact-us'> 
                <button className='red_btn'>Get In Touch</button>
              </Link> 
            </li>
          </ul>
        </nav>
      </div>

     
  );
}

export default App;
