import React from 'react'
import './Enquiry.css'
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';


export default function Enquiry({ closeEnquiry }) {


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [err, setErr] = useState(false)
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [popup, setPopup] = useState(false)



    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        if (name === '' || number === '' || email === '' || message === '') {
            setErr(true);
        } else {
            setErr(false);
            setIsSubmitting(true); // Set the submitting state to true

            emailjs
                .sendForm('service_8ubz4yc', 'template_k9w7fr6', form.current, 'RzLsLo7sLljXvRigI')
                .then((result) => {
                    console.log(result.text);
                    setIsSubmitting(false); // Set the submitting state back to false after successful submission
                    setPopup(true)
                    setName("")
                    setEmail("")
                    setNumber("")
                    setMessage("")
                    setTimeout(() => {
                        closeEnquiry();
                    }, 3000);

                })
                .catch((error) => {
                    console.log(error.text);
                    setIsSubmitting(false); // Set the submitting state back to false after an error
                });
        }
    };

  return (
    <>
    <div className='Enquiry'>
        <div className="dummy"  onClick={closeEnquiry}></div>
         <form className="left_section" ref={form} onSubmit={sendEmail}>
                            <div>
                                <label>Name</label>
                                <input type='text' value={name} onChange={(e) => setName(e.target.value)} name='from_name' placeholder='Enter Your Name'></input>
                            </div>
                            <div>
                                <label>Number</label>
                                <input type='number' value={number} onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 10) {  // Allowing up to 10 digits
                                        setNumber(inputValue);
                                    }
                                }} name='number' maxLength="10" placeholder='Enter Your Number'></input>
                            </div>
                            <div>
                                <label>Email</label>
                                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' placeholder='Enter Your Mail ID'></input>
                            </div>
                            <div>
                                <label>Message</label>
                                <textarea maxLength='200' value={message} onChange={(e) => setMessage(e.target.value)} name='message' placeholder='Type Your Message Here...'></textarea>
                            </div>
                            {err && <p className='error'>Please Fill All the Fields</p>}

                            <button type="submit" value="Send" className='red_btn'> {isSubmitting ? 'Sending' : 'Submit'} </button>
                        </form>
    </div>






    {
                popup &&

                <div className="popup">
                    <div className="dummy" onClick={() => setPopup(false)}></div>
                    <div className='alert'>
                        <p>Thank you for your enquiry. We will get in touch with you shortly !</p>
                        <button className='red_btn' onClick={() => setPopup(false)}>Ok</button>
                    </div>
                </div>
            }
    </>
  )
}
