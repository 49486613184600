import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import HomePage from "./HomePage/HomePage";
import OnlineTraining from "./OnlineTraining/OnlineTraining";
import OfflineTraining from "./OfflineTraining/OfflineTraining";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Seo from "./Seo/Seo";
import Gallery from "./Gallery/Gallery";
import Preloader from "./Preloader/Preloader";
import Supplement from "./Supplement/Supplement";
import SupplementView from "./Supplementview/Supplementview";

function App() {
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setInitialLoad(false); // Set initialLoad to false after the first load
    }, 3000);
  }, []);

  return (
    <>
      {initialLoad && loading ? ( // Display preloader only on the first load
        <Preloader />
      ) : (
        <BrowserRouter>
          <Seo />

          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/online-training" element={<OnlineTraining />} />
            <Route path="/offline-training" element={<OfflineTraining />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/fuelhub" element={<Supplement />} />
            <Route path="/supplement-view/:brand" element={<SupplementView/>} />

          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
