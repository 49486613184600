import React, { useEffect, useRef, useState } from 'react'
import './HomePage.css'
import Header from '../Header/Header'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Footer from '../Footer/Footer'
import CountUp from 'react-countup';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
import Preloader from '../Preloader/Preloader'
import { Link } from 'react-router-dom'
import Enquiry from '../Enquiry/Enquiry';
import Calculator from '../Calculator/Calculator'



export default function HomePage() {

  const [enquiryform,setEnquiryform]=useState(false)


  const EnquireyNow=()=>{
    setEnquiryform(true)
}


const closeEnquiry = () => {
    setEnquiryform(false);
}


  const [counterVisible, setCounterVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [err, setErr] = useState(false)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [popup, setPopup] = useState(false)


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === '' || number === '' || email === '' || message === '') {
      setErr(true);
    } else {
      setErr(false);
      setIsSubmitting(true); // Set the submitting state to true

      emailjs
        .sendForm('service_8ubz4yc', 'template_k9w7fr6', form.current, 'RzLsLo7sLljXvRigI')
        .then((result) => {
          console.log(result.text);
          setIsSubmitting(false); // Set the submitting state back to false after successful submission
          setPopup(true)
          setName("")
          setEmail("")
          setNumber("")
          setMessage("")
        })
        .catch((error) => {
          console.log(error.text);
          setIsSubmitting(false); // Set the submitting state back to false after an error
        });
    }
  };


  useEffect(() => {
    // Initialize AOS when the component mounts
    Aos.init();

    // Check if the counter section is in the viewport
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCounterVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    const counterSection = document.querySelector('.counter');
    if (counterSection) {
      observer.observe(counterSection);
    }

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);





  return (

    <>

    

          <div className='HomePage'>


            <Helmet>
              <title>Muscle Warriors | Best Unisex Gym in Chennai</title>
              <meta name="description" content="Unleash your inner strength and conquer your fitness goals at Muscle Warrior Gym, a unisex fitness center dedicated to sculpting champions." />
              <meta name="keywords" content="Dinesh Narayanan,Body Transformation Chennai,Group Fitness Classes Chennai,Chennai Strength Training,Affordable Gym Chennai,Professional Trainers Chennai,Chennai Fitness Community,Chennai Wellness Center,Exercise Classes in Chennai,Chennai Weight Loss Center,Chennai Nutrition Coaching,Fitness Programs in Chennai,Chennai Gym Reviews,Best Health Club Chennai,Chennai Bodybuilding,Chennai CrossFit,Chennai Yoga Classes,Cardio Workouts in Chennai,Health and Fitness Chennai,Personal Training in Chennai,Weightlifting in Chennai,Muscle Warriors Unisex Gym,Muscle Warrior Gym Chennai,Dinesh Narayanan Gym,Best Workout Place in Chennai,Chennai Gym Membership,,Unisex Gym in Chennai,Muscle Warrior Unisex Gym,Best Gym in Chennai,Muscle Warriors,,Muscle Warrior,Gym near me,Gym in Chennai,Muscle Warrior Chennai,Chennai Fitness Center,Top Fitness Club Chennai,fitness, gym, workouts, health,Gym near me,Fitness center in Chennai,Best gym chennai,chennai fitness classes,Personal training chennai,Workout classes chennai,Women's gym near me,CrossFit gym chennai,Yoga classes chennai,Affordable gym chennai,24-hour gym chennaiBodybuilding gym chennai,muscle warriors gym chennai,muscle warriors fitness center,muscle warriors workout classes,muscle warriors,gym supplements,protien supplements" />
              <meta name="author" content="Rithish Abinav" />
            </Helmet>



            <Header />

            <main>

              <a className="scroll_to_top" href='#top'>
                <i class="fa-solid fa-angle-up"></i>
              </a>


              {/* banner carousal */}

              {/* <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">

  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000">
    <div className="banner" >
          <div className="left_section" data-aos="fade-up">
            <h1>Your Limitation is <br />
              Your <span className='red_text'>Mindset</span>, Get <br />
              Out and Max it <br />
              Out</h1>
            <p className='light_text'>Unlock the true potential of your fitness journey by conquering the barriers of your own mindset. Embrace the belief that limits are self-imposed, and step out of your comfort zone to push your boundaries further than ever before. With determination and a resolute mindset, you have the power to exceed expectations and maximize your fitness achievements.</p>
           <a href='/about-us'><button className='red_btn'>Explore</button></a> 
          </div>
          <div className="right_section" data-aos="fade-down">
            <img loading='lazy' alt='Muscle Warriors'  src={require('../Assets/Hero img.webp')} width='100%' />
          </div>
        </div>    </div>
    <div class="carousel-item" data-bs-interval="2000">
    <div className="download_app sec">
  <div className="left_section">
    <img src={require('../Assets/iphone mockupcut 1.png')}  data-aos="fade-right"/>
  </div>
  <div className="right_section">
    <section>
      <h3>Elevate Your Experience with Our Mobile App!</h3>
      <p>Unlock Exclusive Features and Stay Connected Anywhere, Anytime <br/><br/>Download Our App on</p>
      <div>
        <a  data-aos="fade-left" href=''><img src={require('../Assets/gp.png')}/> </a>
        <a  data-aos="fade-left" href=''><img src={require('../Assets/as.png')}/> </a>

      </div>
    </section>
  </div>
</div>    </div>
    <div class="carousel-item" data-bs-interval="2000">
    <div className="supplement">
<section> 
   <div className="left_section" data-aos="fade-right">
    <h1>Unleash Your Power with <br/><span>Muscle Warriors</span></h1>
    <p className='light_text'>Elevate your fitness journey with our premium supplements designed to push your limits and redefine your strength goals. Ignite your workouts with explosive energy using our Pre-Workout Ignite formula. Fuel muscle growth and rapid recovery with our Protein Evolution blend. Recharge and bounce back stronger with our advanced RecoverX Formula. Plus, ensure your foundation is strong with our Joint Support+.</p>

<button className='red_btn'>Shop Now</button>
  </div>
  <div className="right_section"  data-aos="fade-left">
    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Supplemant banner image.webp')} width='100%' />
  </div>
  </section>

</div>    </div>
  </div>

 
</div> */}


<div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <ol class="carousel-indicators">
    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1"></li>
    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2"></li>
    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3"></li>
  </ol>

  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
      <div className="banner">
        <div className="left_section" data-aos="fade-up">
          <h1>
            Your Limitation is <br />
            Your <span className='red_text'>Mindset</span>, Get <br />
            Out and Max it <br />
            Out
          </h1>
          <p className='light_text'>
            Unlock the true potential of your fitness journey by conquering the barriers of your own mindset. Embrace the belief that limits are self-imposed, and step out of your comfort zone to push your boundaries further than ever before. With determination and a resolute mindset, you have the power to exceed expectations and maximize your fitness achievements.
          </p>
          <Link to='/about-us'><button className='red_btn'>Explore</button></Link>
        </div>
        <div className="right_section" data-aos="fade-down">
          <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Hero img.webp')} width='100%' />
        </div>
      </div>
    </div>

    <div class="carousel-item" data-bs-interval="5000">
      <div className="download_app">
        <div className="download_btn">
          <a href='https://play.google.com/store/apps/details?id=com.jcs.fitsw.musclewarriors&pcampaignid=web_share'><button><img src={require('../Assets/gp.png')} alt="Google Play" /> </button></a>
          <a href='https://apps.apple.com/in/app/muscle-warriors/id6451274660'><button><img src={require('../Assets/as.png')} alt="Apple App Store" /> </button></a>
        </div>
      </div>
    </div>

    <div class="carousel-item" data-bs-interval="5000">
      <div className="supplement">
        <section>
          <div className="left_section" data-aos="fade-right">
            <h1>Unleash Your Power with <br /> Fuel<span> Hub</span></h1>
            <p className='light_text'>Elevate your fitness journey with our premium supplements designed to push your limits and redefine your strength goals. Ignite your workouts with explosive energy using our Pre-Workout Ignite formula. Fuel muscle growth and rapid recovery with our Protein Evolution blend. Recharge and bounce back stronger with our advanced RecoverX Formula. Plus, ensure your foundation is strong with our Joint Support+.</p>
            <Link to='/fuelhub'><button className='red_btn'>Shop Now</button></Link>
          </div>
          <div className="right_section" data-aos="fade-left">
            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Supplemant banner image.png')} width='100%' />
          </div>
        </section>
      </div>
    </div>

    <div class="carousel-item" data-bs-interval="5000">
      <div className="franchise_banner">
        {window.innerWidth <= 768 ? (
          <img src={require('../Assets/mfbanner.jpg')} alt="Mobile Franchise" width="100%" />
        ) : (
          <img src={require('../Assets/NEWFRANCHISE.jpg')} alt="Desktop Franchise" width="100%" />
        )}
        <Link to="/contact-us"><button className='red_btn'>Get Started</button></Link>
      </div>
    </div>
  </div>
</div>



              {/* banner section */}

              {/* <div className="banner" >
          <div className="left_section" data-aos="fade-up">
            <h1>Your Limitation is <br />
              Your <span className='red_text'>Mindset</span>, Get <br />
              Out and Max it <br />
              Out</h1>
            <p className='light_text'>Unlock the true potential of your fitness journey by conquering the barriers of your own mindset. Embrace the belief that limits are self-imposed, and step out of your comfort zone to push your boundaries further than ever before. With determination and a resolute mindset, you have the power to exceed expectations and maximize your fitness achievements.</p>
           <a href='/about-us'><button className='red_btn'>Explore</button></a> 
          </div>
          <div className="right_section" data-aos="fade-down">
            <img loading='lazy' alt='Muscle Warriors'  src={require('../Assets/Hero img.webp')} width='100%' />
          </div>
        </div> */}

              {/* challenge section */}

              <div className="challenge" data-aos="fade-right">
                <h3 className='heading'>Are You Ready To <span className='red_text'>Challenge</span> Yourself ?</h3>
                <div className="gallery">
                  <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/g1.webp')} />
                  <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/g2.webp')} />
                  <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/g3.webp')} />
                  <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/g4.webp')} />
                  <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/g5.webp')} />
                </div>
              </div>

              {/* our trainings */}

              {/* old */}

              {/* <div className="our_trainings">
          <h3 className='heading'>Our Trainings</h3>

          <div className="_badges">
            <div className="_badge">
              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/t1.webp')} />

              <p className="overlay_text light_text">
                Bench press is a prime upper body exercise that targets chest, shoulders, and triceps muscles, promoting significant strength development.<br /><br />

                Regular bench press workouts contribute to sculpting a well-defined chest, enhancing your overall upper body aesthetics.<br /><br />

                Improved pushing strength from bench press translates into daily activities, making tasks like lifting objects and pushing easier.<br /><br />

                Achieving personal bench press milestones can significantly enhance your self-confidence and self-esteem.<br /><br />
              </p>
              <button>Bench Press</button>
            </div>


            <div className="_badge">
              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/t2.webp')} />

              <p className="overlay_text light_text">
                Body balance exercises are essential for overall stability and well-being. These exercises focus on enhancing your core strength, posture, and stability, leading to improved balance and coordination.<br /><br />
                Body balance exercises engage your core muscles, including your abdominals, obliques, and lower back muscles. This not only strengthens your core but also helps in preventing lower back pain.<br /><br />
                Regularly performing body balance exercises helps you become more aware of your body's alignment. This awareness translates into better posture throughout your daily activities.<br /><br />
              </p>
              <button>Body Balance</button>
            </div>



            <div className="_badge">
              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/t3.webp')} />

              <p className="overlay_text light_text">
                Enhanced muscle mass translates to greater strength for daily activities.<br /><br />
                More muscle means a higher resting metabolism, aiding in weight management.<br /><br />
                Regular exercise reduces the risk of heart disease and improves cardiovascular function.<br /><br />
                Strong muscles support easier movement and prevent injuries.<br /><br />
                Bodybuilding fosters discipline, focus, and determination.</p>
              <button>Body Building</button>
            </div>




            <div className="_badge">
              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/t4.webp')} />

              <p className="overlay_text light_text">
                Deadlifts engage multiple muscle groups, enhancing overall strength.<br /><br />
                Improved core stability and lifting mechanics boost daily activities.<br /><br />
                Compound movements like deadlifts can accelerate calorie burn.<br /><br />
                Weight-bearing exercises promote strong bones and reduce injury risk.<br /><br />
                Deadlifts shape both body and mind, empowering you in and out of the gym.</p>
              <button>Dead Weight Lifting</button>
            </div>

          </div>

          <button className='red_btn viewgallery_btn'>View Gallery <i class="fa-solid fa-angles-right"></i></button>

        </div> */}


              {/* new */}


              <div className="our_trainings">
                <h3 className='heading'>Our Trainings</h3>

                <div className="_badges">
                  <div className="_badge">
                    <img loading='lazy' alt='Strength training' src={require('../Assets/strengthtraining.webp')} />

                    <p className="overlay_text light_text">
                      Strength training not only builds muscle and burns fat but also improves bone density. Elevate your energy levels and metabolism while sculpting your physique. Experience newfound confidence and resilience as you conquer new challenges. Start your journey to a fitter, healthier lifestyle today!
                    </p>
                    <button>Strength training</button>
                  </div>


                  <div className="_badge">
                    <img loading='lazy' alt='Endurance' src={require('../Assets/endurance.webp')} />

                    <p className="overlay_text light_text">
                      Elevate cardiovascular fitness, improve lung efficiency, and enhance overall stamina. Achieve a leaner physique while effectively managing weight and boosting metabolism. Conquer challenges, both physical and mental, fostering resilience and a positive mindset. Unleash your full potential with the empowering benefits of endurance training!
                    </p>
                    <button>Endurance</button>
                  </div>



                  <div className="_badge">
                    <img loading='lazy' alt='Lifestyle transformation' src={require('../Assets/lifestyletraining.webp')} />

                    <p className="overlay_text light_text">
                      Embrace a New You with Lifestyle Transformation Workouts: Witness remarkable improvements in strength, flexibility, and energy levels. Shed excess weight and foster a healthier metabolism through sustainable changes. Enhance mental clarity and positivity while reducing stress. Experience a holistic transformation that uplifts both body and mind!</p>
                    <button>Lifestyle transformation </button>
                  </div>




                  <div className="_badge">
                    <img loading='lazy' alt='contest preparation' src={require('../Assets/contestprep.webp')} />

                    <p className="overlay_text light_text">
                      We specialize in helping individuals prepare for these exact types of competitions. Our tailored contest preparation program encompasses a range of essential elements, including personalized workout routines, nutrition plans, and guidance on posing and presentation. We understand the unique demands of bodybuilding and physique contests, and we have a team of experienced trainers and coaches who can provide the expertise and support needed to excel on the competition stage.</p>
                    <button>Contest prep</button>
                  </div>

                  <div className="_badge">
                    <img loading='lazy' alt='weight lifting' src={require('../Assets/weightlifting.webp')} />

                    <p className="overlay_text light_text">
                      Build lean muscle mass, boost metabolism, and achieve a sculpted physique. Strengthen bones, improve joint health, and enhance overall functional strength. Elevate confidence as you set and surpass lifting goals, fostering a sense of accomplishment. Experience a balanced, empowered, and resilient body through the power of weight lifting!</p>
                    <button>Weight lifting</button>
                  </div>

                  <div className="_badge">
                    <img loading='lazy' alt='Power lifting' src={require('../Assets/powerlifting.webp')} />

                    <p className="overlay_text light_text">
                      Develop maximum muscle power, enhance overall strength, and amplify athletic performance. Build functional muscle mass while refining lifting techniques for optimal results. Boost metabolism, aid in weight management, and foster a confident body image. Experience the empowering journey of powerlifting, transforming not just your physique, but your mindset!</p>
                    <button>Power lifting</button>
                  </div>

                </div>

               <Link style={{textDecoration:"none"}} to='/gallery'> <button className='red_btn viewgallery_btn'>View Gallery <i class="fa-solid fa-angles-right"></i></button></Link> 

              </div>





              {/* supplement banner */}
              {/* <div className="supplement">
<h3 className='heading'>Fuel Hub</h3>

<section> 
   <div className="left_section" data-aos="fade-right">
    <h1>Unleash Your Power with <br/><span>Muscle Warriors</span></h1>
    <p className='light_text'>Elevate your fitness journey with our premium supplements designed to push your limits and redefine your strength goals. Ignite your workouts with explosive energy using our Pre-Workout Ignite formula. Fuel muscle growth and rapid recovery with our Protein Evolution blend. Recharge and bounce back stronger with our advanced RecoverX Formula. Plus, ensure your foundation is strong with our Joint Support+.</p>

<button className='red_btn'>Shop Now</button>
  </div>
  <div className="right_section"  data-aos="fade-left">
    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Supplemant banner image.webp')} width='100%' />
  </div>
  </section>

</div> */}

              {/* why choose us */}

              <div className="whychoose_us">
                <h3 className='heading'>Why Choose Us ?</h3>

                <section>
                  <div className="left_section" data-aos="fade-right">
                    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/wcu.webp')} width='100%' />
                  </div>
                  <div className="right_section" data-aos="fade-left">
                    <h1>We Don’t Just Train<br />
                      You, We Train With <br />
                      You</h1>
                    <p className='light_text'>it's a shared journey. We don't just guide you, we sweat it out alongside you, pushing limits and celebrating victories together. Join us to experience the power of unity in fitness. Your goals are our goals!</p>
                  <Link to="/contact-us"><button className='red_btn'>Join Community</button></Link>
                  </div>
                </section>
              </div>

              {/* our trainers */}

              <div className="our_trainers">
                <h3 className='heading'>Our Trainers</h3>
                <div className="_badges">

                  <div className="_badge">
                    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/tt1.jpg')} />
                    <div className="content">
                      <h2 className='trainer_name'>Dinesh Narayanan</h2>
                      <p>CEO</p>
                    </div>
                  </div>


                  <div className="_badge flip">
                    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/tt2.jpg')} />
                    <div className="content">
                      <h2 className='trainer_name'>Sam</h2>
                      <p>Instructor</p>
                    </div>

                  </div>

                  <div className="_badge">
                    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/tt3.jpg')} />
                    <div className="content">
                      <h2 className='trainer_name'>Ganesh</h2>
                      <p>Senior Instructor</p>
                    </div>
                  </div>

                </div>
              </div>

              {/* counter */}

              {/* old */}
              {/* <div className="counter">
          <div className="overlay"></div>
          <div className="_badges">
            <div className="_badge">
              <h1>30 +</h1>
              <p>Training Programs</p>
            </div>

            <div className="_badge">
              <h1>3 +</h1>
              <p>Experts</p>
            </div>

            <div className="_badge">
              <h1>3</h1>
              <p>Years of Service</p>
            </div>

            <div className="_badge">
              <h1>1500 +</h1>
              <p>Satisfied Clients</p>
            </div>
          </div>

        </div> */}



              {/* new */}
              <div className="counter">
                <div className="overlay"></div>
                <div className="_badges">
                  <div className="_badge">
                   
                          <div>
                            <h1>30</h1>
                            <p >+</p>
                          </div>
                               <p>Training Programs</p>
                  </div>

                  <div className="_badge">
                 
                    
                          <div>
                            <h1 >12</h1>
                            <p >+</p>
                          </div>
                       
                    <p>Experts</p>
                  </div>

                  <div className="_badge">
                  
                        <h1>3</h1>
                     
                    <p>Years of Service</p>
                  </div>

                  <div className="_badge">
                   
                          <div>
                            <h1>1500</h1>
                            <p >+</p>
                          </div>
                       
                    <p>Satisfied Clients</p>
                  </div>
                </div>

              </div>



              {/* Program Pricing */}
              <div className="pricing">
                <h3 className='heading'>Program Pricing</h3>
                <div className="_badges">

                  <div className="_badge" >
                    <h3>Monthly Plan</h3>
                    {/* <h5>$250/Person</h5> */}
                    <p className='light_text'>Our Monthly Plan offers flexibility and convenience for your fitness journey, allowing you to get started without a long-term commitment. It's ideal for individuals with dynamic schedules looking to achieve their health goals on their terms.</p>
                    <ul>
                      <li>Unlimited access to gym environment and all equipments</li>
                      <li>Free Fitness Assesmant</li>
                      <li>Free Fitness consultaion with Senior Instructor</li>
                      <li>No hidden/Admission charges</li>
                
                    </ul>
                    <button onClick={EnquireyNow} className='subscribe_btn'>Enquire Now</button>
                  </div>

                  <div className="_badge" >
                    <h3>Quarterly Plan</h3>
                    {/* <h5>$250/Person</h5> */}
                    <p className='light_text'>With our Quarterly Plan, you can commit to your fitness journey, save more, and set yourself up for sustained success. It's perfect for those who want to see significant results while enjoying cost savings.</p>
                    <ul>
                    <li>Unlimited access to gym environment and all equipments</li>
                      <li>Free Fitness Assesmant</li>
                      <li>Free Fitness consultaion with Senior Instructor</li>
                      <li>No hidden/Admission charges</li>
                      <li>General Workout plan  </li>
                    </ul>
                    <button onClick={EnquireyNow} className='subscribe_btn'>Enquire Now</button>
                  </div>

                  <div className="_badge">
                    <h3>Six-Month Plan</h3>
                    {/* <h5>$250/Person</h5> */}
                    <p className='light_text'>Experience lasting fitness gains and maximize value with our Half-Yearly Plan. It's an excellent choice for individuals committed to long-term health and strength, offering substantial transformation and financial benefits.</p>
                    <ul>
                    <li>Unlimited access to gym environment and all equipments</li>
                      <li>Free Fitness Assesmant</li>
                      <li>Free Fitness consultaion with Senior Instructor</li>
                      <li>No hidden/Admission charges</li>
                      <li>General Workout plan & Free diet plan </li>
                    </ul>
                    <button onClick={EnquireyNow} className='subscribe_btn'>Enquire Now</button>
                  </div>

                  <div className="_badge">
                    <h3>Yearly Plan</h3>
                    {/* <h5>$250/Person</h5> */}
                    <p className='light_text'>Maximize your fitness journey with our Annual Plan, unlocking the best value for a full year of wellness. It's the ultimate choice for those dedicated to their health, ensuring sustained results with exceptional savings.</p>
                    <ul>
                    <li>Unlimited access to gym environment and all equipments</li>
                      <li>Free Fitness Assesmant</li>
                      <li>Free Fitness consultaion with Senior Instructor</li>
                      <li>No hidden/Admission charges</li>
                      <li>General Workout plan & Free diet plan </li>
                      <li>Steam Bath(Monthly twice)</li>
                      <li>Free Gifts(Gym Bag/T-shirt/Shaker)</li>
                    </ul>
                    <button onClick={EnquireyNow} className='subscribe_btn'>Enquire Now</button>
                  </div>





                </div>
              </div>

            </main>

            {/* download our app */}
            {/* <div className="download_app sec">
  <div className="left_section">
    <img src={require('../Assets/iphone mockupcut 1.png')}  data-aos="fade-right"/>
  </div>
  <div className="right_section">
    <section>
      <h3>Elevate Your Experience with Our Mobile App!</h3>
      <p>Unlock Exclusive Features and Stay Connected Anywhere, Anytime <br/><br/>Download Our App on</p>
      <div>
        <a  data-aos="fade-left" href=''><img src={require('../Assets/gp.png')}/> </a>
        <a  data-aos="fade-left" href=''><img src={require('../Assets/as.png')}/> </a>

      </div>
    </section>
  </div>
</div> */}
            <main>
              {/* What Our Clients Say About Us */}

              <div className="review">
                <h3 className='heading'>What Our Clients Say About Us ?</h3>

                <div id="carouselExampleIndicators" class="carousel slide" data-bs-touch="true">

                  <div class="carousel-inner">
                    <div class="carousel-item active ">
                      <section>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r3.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Lokesh Loki</h5>
                                <p className='reviewer_location'>Kodungaiyur,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            Good ambience out in zone and good air ventilation. Every fitness enthusiast must check out ❤️                    </p>
                        </div>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r1.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Ajayan Ramadoss</h5>
                                <p className='reviewer_location'>Madhavarm,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            I started up with muscle warriors at April 2021.

                            *MUSCLE WARRIORS*

                            The new fitness station in North Chennai 🔥🔥

                            The best gym i have ever experienced in my life such good atmosphere everything was good the machines, equipments, maintanence everthing was awesome and  U can see new machines more dumbells benches none other has these kind of good equipment. And

                            Dinesh Bro,
                            I really appreciate your patience on answering my all queries related to gym and fitness.  I have noted your all advices for effective fitness and importance of diets.Thanks for sharing your knowledge.your good response  really encouraged me to join muscle warriors.

                            Nizam bro,
                            He is such a good kind hearted and good motivator and  good person.

                            I highly encouraged newbies to  join muscle warriors gym. You will see Good environment and good experience.The equipment is well maintained and caretakers are like buddies,you will enjoy.
                            GT (general training) PT (personal training) both are amazing.                    </p>
                        </div>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r2.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Kandhavel Anandan</h5>
                                <p className='reviewer_location'>Madhavarm,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            One of the Best gyms in North Chennai. It has Equipments customised by Mr. Dinesh Narayan and has very good ambience !!! 💪🏽 💯

                            I felt the value of Muscle Warriors gym when I was thinking to change gym after shifting my residence to Shenoy Nagar. Many other gyms are purely business oriented with imported equipments. But Mr. Dinesh Narayan has customised Equipments with passion for maximum muscle growth.

                            I am very happy with Muscle Warriors Gym and improvement in my physique after joining this Gym.                    </p>
                        </div>

                      </section>

                    </div>
                    <div class="carousel-item  ">
                      <section>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r4.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Rebecca Amardeep</h5>
                                <p className='reviewer_location'>Perambur,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            The best gym in North chennai. Customized equipments and amazing ambience. Coaches are really understanding and beginner friendly.                    </p>
                        </div>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r5.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Kailash S</h5>
                                <p className='reviewer_location'>Vyasarpadi,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            Great Ambience,Professional Trainers,Effective equipments
                          </p>
                        </div>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r6.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Sanjeev Kumar</h5>
                                <p className='reviewer_location'>Kolathur,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            The best place for our body and mind. Friendly trainers, neat ambience .                    </p>
                        </div>
                      </section>
                    </div>
                    <div class="carousel-item ">
                      <section>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r7.webp')} />
                              <span>
                                <h5 className='reviewer_name'>selvamani mg</h5>
                                <p className='reviewer_location'>Kodungaiyur,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            Ambience and trainers are too good....with good and clean maintained gym....they properly guide you to better body transformation.....                    </p>
                        </div>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r8.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Raja Sundar</h5>
                                <p className='reviewer_location'>Madhavarm,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            One of the best GYM in North chennai and filled with friendly trainers . I am very much surprised, seeing single star reviews from some ppls. Hope i never seen them in muscle warriors before and now !!! 100%  worth for your hard earned money 💰                    </p>
                        </div>
                        <div className="_badge">
                          <div className="top">
                            <div className="left">
                              <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Reviews/r9.webp')} />
                              <span>
                                <h5 className='reviewer_name'>Shyam Casper</h5>
                                <p className='reviewer_location'>KKD Nagar,Chennai</p>
                              </span>
                            </div>
                            <div className="right">
                              <h3>4.5</h3><i class="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <p className="content">
                            Am one of the client in muscle warriors for past 6 months.. they provided best training session, best workout programs and providing branded supplements..                    </p>
                        </div>
                      </section>
                    </div>
                  </div>


                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div className="navigation_btn">
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span><i class="fa-solid fa-arrow-left"></i>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span><i class="fa-solid fa-arrow-right"></i>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>



              {/* calorie calculator */}
              <div className="calculator sec">
              <h3 className='heading'>Calculate Your Daily Caloric Needs</h3>
              <p>Our calorie calculator provides a simple and effective way to determine your daily caloric needs, helping you make informed decisions about your diet and fitness goals.</p>
             <Calculator/>
             <p className='note'>If you're pregnant or breast-feeding, are a competitive athlete, or have a metabolic disease, such as diabetes, the calorie calculator may overestimate or underestimate your actual calorie needs.</p>

              </div>

              {/* contact */}

              <div className="contact">
                <h3 className='heading'>Contact Us</h3>
                <p>Will be available to answer all your Enquiries and Requests, Send us message today. Our team are ready to give you best service</p>

                <section>
                  <div className="left_section" data-aos="fade-right">
                    <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/Contact image.webp')} width='100%' />
                  </div>
                  <form className="right_section" ref={form} onSubmit={sendEmail} data-aos="fade-left">
                    <div>
                      <label>Name</label>
                      <input type='text' value={name} onChange={(e) => setName(e.target.value)} name='from_name' placeholder='Enter Your Name'></input>
                    </div>
                    <div>
                      <label>Number</label>
                      <input type='number' value={number} onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 10) {  // Allowing up to 10 digits
                          setNumber(inputValue);
                        }
                      }} name='number' maxLength="10" placeholder='Enter Your Number'></input>
                    </div>
                    <div>
                      <label>Email</label>
                      <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' placeholder='Enter Your Mail ID'></input>
                    </div>
                    <div>
                      <label>Message</label>
                      <textarea maxLength='200' value={message} onChange={(e) => setMessage(e.target.value)} name='message' placeholder='Type Your Message Here...'></textarea>
                    </div>
                    {err && <p className='error'>Please Fill All the Fields</p>}

                    <button type="submit" value="Send" className='red_btn'> {isSubmitting ? 'Sending' : 'Submit'} </button>
                  </form>
                </section>

              </div>

            </main>
            <Footer />
            {enquiryform?<Enquiry closeEnquiry={closeEnquiry}/>:""}
            {
              popup &&

              <div className="popup">
                <div className="dummy" onClick={() => setPopup(false)}></div>
                <div className='alert'>
                  <p>Thank you for your enquiry. We will get in touch with you shortly !</p>
                  <button className='red_btn' onClick={() => setPopup(false)}>Ok</button>
                </div>
              </div>
            }
          </div>

      

    </>




  )
}
