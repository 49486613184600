import React from 'react'
import './OfflineTraining.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet';
import Enquiry from '../Enquiry/Enquiry';
import { useState } from 'react';
import { useEffect } from 'react';


export default function OfflineTraining() {

    useEffect(() => {
        // Scroll to the top with an immediate behavior
        window.scrollTo({ top: 0, behavior: 'instant' });
      }, []);

    const [enquiryform,setEnquiryform]=useState(false)




    const EnquireyNow=()=>{
        setEnquiryform(true)
    }


    const closeEnquiry = () => {
        setEnquiryform(false);
    }

    return (
        <div className='OfflineTraining'>
            <Helmet>
                <title>Muscle Warriors | Offline Training</title>
                <meta name="description" content="Unleash your inner strength and conquer your fitness goals at Muscle Warrior Gym, a unisex fitness center dedicated to sculpting champions." />
                <meta name="keywords" content="Dinesh Narayanan,Body Transformation Chennai,Group Fitness Classes Chennai,Chennai Strength Training,Affordable Gym Chennai,Professional Trainers Chennai,Chennai Fitness Community,Chennai Wellness Center,Exercise Classes in Chennai,Chennai Weight Loss Center,Chennai Nutrition Coaching,Fitness Programs in Chennai,Chennai Gym Reviews,Best Health Club Chennai,Chennai Bodybuilding,Chennai CrossFit,Chennai Yoga Classes,Cardio Workouts in Chennai,Health and Fitness Chennai,Personal Training in Chennai,Weightlifting in Chennai,Muscle Warriors Unisex Gym,Muscle Warrior Gym Chennai,Dinesh Narayanan Gym,Best Workout Place in Chennai,Chennai Gym Membership,,Unisex Gym in Chennai,Muscle Warrior Unisex Gym,Best Gym in Chennai,Muscle Warriors,,Muscle Warrior,Gym near me,Gym in Chennai,Muscle Warrior Chennai,Chennai Fitness Center,Top Fitness Club Chennai,fitness, gym, workouts, health,Gym near me,Fitness center in Chennai,Best gym chennai,chennai fitness classes,Personal training chennai,Workout classes chennai,Women's gym near me,CrossFit gym chennai,Yoga classes chennai,Affordable gym chennai,24-hour gym chennaiBodybuilding gym chennai,muscle warriors gym chennai,muscle warriors fitness center,muscle warriors workout classes,muscle warriors,gym supplements,protien supplements" />
                <meta name="author" content="Rithish Abinav" />
            </Helmet>

            <Header />

            <main>
                {/* banner */}

                <div className="banner">
                    <h1>Personal Training Programs</h1>
                </div>

                <p className='banner_content'>At <span>Muscle Warriors</span>, we believe that a supportive community and expert guidance can elevate your fitness journey to new heights. Our Offline Training Programs are designed to provide you with hands-on coaching, personalized attention, and an environment that fuels your motivation.</p>


                {/* benefits */}
                <div className="benefits sec">
                    <h3 className='heading'>The <span>Benefits</span> of Offline Training</h3>

                    <div className="_badges">

                        <div className="_badge">
                            <div className="icon">
                                <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/fti1.webp')}></img>
                            </div>
                            <h4>IN PERSON COACHING</h4>
                            <p>Experience real-time guidance from our certified trainers. Benefit from immediate feedback, form correction, and motivation to push your limits.</p>
                        </div>

                        <div className="_badge">
                            <div className="icon">
                                <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/fti2.webp')}></img>
                            </div>
                            <h4>CUSTOM WORKOUTS</h4>
                            <p>Our trainers craft workouts tailored to your fitness level, goals, and preferences. We ensure that every session is challenging yet enjoyable.</p>
                        </div>

                        <div className="_badge">
                            <div className="icon">
                                <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/fti3.webp')}></img>
                            </div>
                            <h4>COMMUNITY CONNECTION</h4>
                            <p>Join a community of like-minded individuals who share your commitment to wellness. Forge friendships, find workout partners, and celebrate each other's achievements.</p>
                        </div>

                    </div>
                </div>

                {/* How It Works ? */}
                <div className="how_works sec">
                    <h3 className="heading">How It Works ?</h3>

                    <section>
                        <div className="left_section">

                            <div>
                                <p><span>Choose Your Program</span> :  Explore our range of offline training programs, including strength training, group classes, and specialized workshops. Select the program that resonates with your goals.</p>
                            </div>

                            <div>
                                <p><span>Personal Consultation</span> : When you sign up, we'll schedule a one-on-one consultation to understand your fitness background and aspirations. This helps us create a program tailored to you.</p>
                            </div>

                            <div>
                                <p><span>Scheduled Sessions</span> : Attend your scheduled training sessions at our state-of-the-art gym facility. Our trainers will guide you through workouts that challenge and inspire you.</p>
                            </div>

                            <div>
                                <p><span>Progress Tracking</span> : We're invested in your progress. Our trainers will monitor your achievements, adjust your plan as needed, and provide ongoing support and motivation.</p>
                            </div>

                        </div>
                        <div className="right_section">

                            <div>
                                <iframe width="100%" height="400" src="https://www.youtube.com/embed/tZ_Y7BjjUEA?si=2n6WZuhLU00-L0Yf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>


                        </div>
                    </section>

                </div>

                {/* Begin Your Fitness Journey Today! */}
                {/* <div className="begin sec">
                <h3 className="heading">Begin Your Fitness Journey Today!</h3>
                <p>Elevate your fitness experience with our Offline Training Programs. Join us at Muscle Warriors and immerse yourself in a dynamic, results-driven training environment that caters to your unique goals.</p>
                <div className="_badges">

<div className="_badge" >
  <h3>Monthly Plan</h3>

  <p className='light_text'>Getting to a desire stage in training require you to have instructor that will getyou there faster. Our Instructor are here to walk the journey with you</p>
  <ul>
    <li>Unlimited access to gym environment and selected tools.</li>
    <li>Unlimited Access to Meeting rooms</li>
    <li>Part-Time Instructor Oversee</li>
    <li>Limited Water Access For Refreshment</li>
    <li>30ml Water Can</li>
  </ul>
  <button className='subscribe_btn'>Subscribe Now</button>
</div>

<div className="_badge" >
  <h3>Quarterly Plan</h3>

  <p className='light_text'>Getting to a desire stage in training require you to have instructor that will getyou there faster. Our Instructor are here to walk the journey with you</p>
  <ul>
    <li>Unlimited access to gym environment and selected tools.</li>
    <li>Unlimited Access to Meeting rooms</li>
    <li>Part-Time Instructor Oversee</li>
    <li>Limited Water Access For Refreshment</li>
    <li>30ml Water Can</li>
  </ul>
  <button className='subscribe_btn'>Subscribe Now</button>
</div>

<div className="_badge">
  <h3>Six-Month Plan</h3>

  <p className='light_text'>Getting to a desire stage in training require you to have instructor that will getyou there faster. Our Instructor are here to walk the journey with you</p>
  <ul>
    <li>Unlimited access to gym environment and selected tools.</li>
    <li>Unlimited Access to Meeting rooms</li>
    <li>Part-Time Instructor Oversee</li>
    <li>Limited Water Access For Refreshment</li>
    <li>30ml Water Can</li>
  </ul>
  <button className='subscribe_btn'>Subscribe Now</button>
</div>

<div className="_badge">
  <h3>Yearly Plan</h3>
 
  <p className='light_text'>Getting to a desire stage in training require you to have instructor that will getyou there faster. Our Instructor are here to walk the journey with you</p>
  <ul>
    <li>Unlimited access to gym environment and selected tools.</li>
    <li>Unlimited Access to Meeting rooms</li>
    <li>Part-Time Instructor Oversee</li>
    <li>Limited Water Access For Refreshment</li>
    <li>30ml Water Can</li>
  </ul>
  <button className='subscribe_btn'>Subscribe Now</button>
</div>





</div>
                </div> */}


                {/* train with dinesh narayanan */}
                <div className="twdn sec ">
                    <h3 className="heading">Train with <span>Dinesh Narayanan</span></h3>
                    <p>Join renowned fitness expert Dinesh Narayanan and transform your health and well-being. Dinesh's expertise and personalized training will guide you toward your fitness goals like never before.</p>
                    <section>
                        <div className="left ">
                            <div>
                                <div>
                                    <h3>Lifestyle Training</h3>
                                    <p className='light_text'>Experience a transformation in your lifestyle with our comprehensive Lifestyle Training program. It's not just about fitness; it's about enhancing your well-being, happiness, and productivity</p>

                                    <ul>
                                        <li>Fitness Assessment</li>
                                        <li>Customized Diet plan (Veg/Non-Veg)</li>
                                        <li>Customized Workout plan</li>
                                        <li>Supplement Guidance</li>
                                        <li>Progress Tracking</li>
                                    </ul>
                                    <p className='note'>Plan available on Monthly & Quarterly option.</p>
                                </div>
                                <button onClick={EnquireyNow} className='enquire_btn'>Enquire Now</button>
                            </div>

                            <div>
                                <div>
                                    <h3>Contest Prep</h3>
                                    <p className='light_text'>Are you ready to take your physique to the next level? Our Contest Prep program is designed to help you shine on stage, whether it's for bodybuilding, physique competitions, or fitness contests.</p>

                                    <ul>
                                        <li>Fitness Assessment</li>
                                        <li>Blood work</li>
                                        <li>Diet plan</li>
                                        <li>Workout Plan</li>
                                        <li>Supplement Guidance</li>
                                        <li>Safety Precaution</li>
                                        <li>PED Support</li>
                                        <li>PCT (No Additional Charges)</li>
                                        <li>Posing Practice</li>
                                        <li>Weekly form check</li>
                                    </ul>
                                </div>
                                <button onClick={EnquireyNow} className='enquire_btn'>Enquire Now</button>

                            </div>

                        </div>

                        <div className="right">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/glMjvibqb-k?si=iJuaILEhyKGowi2z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        </div>
                    </section>
                </div>

                {/* train with fitness experts */}

                <div className="twfx sec">
                <h3 className="heading">Train with Our Fitness Experts</h3>
                    <p>Embark on a transformative fitness journey by training with our highly skilled and passionate fitness experts. We're committed to helping you achieve your health and wellness goals, with personalized guidance, professional insight, and unwavering support.</p>
                
                <section>

<div className="left">
<div>
                                <div>
                                    <h3>Lifestyle Training</h3>
                                    <p className='light_text'>Experience a transformation in your lifestyle with our comprehensive Lifestyle Training program. It's not just about fitness; it's about enhancing your well-being, happiness, and productivity</p>

                                    <ul>
                                        <li>Fitness Assessment</li>
                                        <li>Customized Diet plan (Veg/Non-Veg)</li>
                                        <li>Customized Workout plan</li>
                                        <li>Supplement Guidance</li>
                                        <li>Progress Tracking</li>
                                    </ul>
                                    <p className='note'>Plan available on Monthly & Quarterly option.</p>
                                </div>
                                <button onClick={EnquireyNow} className='enquire_btn'>Enquire Now</button>
                            </div>
</div>
<div className="right">
    <div className="train1">
        <img src={require('../Assets/tt1.jpg')} width='100%'/>
    </div>
    <div className="train2">
    <img src={require('../Assets/tt2.jpg')} width='100%'/>
    </div>
    <div className="train3">
    <img src={require('../Assets/tt3.jpg')} width='100%'/>
    </div>
</div>

                </section>
                
                
                </div>

            </main>
            <Footer />


{enquiryform?<Enquiry closeEnquiry={closeEnquiry}/>:""}

        </div>
    )
}
