import React from 'react'
import './About.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';


export default function About() {


    useEffect(() => {
        // Scroll to the top with an immediate behavior
        window.scrollTo({ top: 0, behavior: 'instant' });
      }, []);


    return (
        <div className='About'>

            <Helmet>
                <title>Muscle Warriors | About Us</title>
                <meta name="description" content="Unleash your inner strength and conquer your fitness goals at Muscle Warrior Gym, a unisex fitness center dedicated to sculpting champions." />
                <meta name="keywords" content="Dinesh Narayanan,Body Transformation Chennai,Group Fitness Classes Chennai,Chennai Strength Training,Affordable Gym Chennai,Professional Trainers Chennai,Chennai Fitness Community,Chennai Wellness Center,Exercise Classes in Chennai,Chennai Weight Loss Center,Chennai Nutrition Coaching,Fitness Programs in Chennai,Chennai Gym Reviews,Best Health Club Chennai,Chennai Bodybuilding,Chennai CrossFit,Chennai Yoga Classes,Cardio Workouts in Chennai,Health and Fitness Chennai,Personal Training in Chennai,Weightlifting in Chennai,Muscle Warriors Unisex Gym,Muscle Warrior Gym Chennai,Dinesh Narayanan Gym,Best Workout Place in Chennai,Chennai Gym Membership,,Unisex Gym in Chennai,Muscle Warrior Unisex Gym,Best Gym in Chennai,Muscle Warriors,,Muscle Warrior,Gym near me,Gym in Chennai,Muscle Warrior Chennai,Chennai Fitness Center,Top Fitness Club Chennai,fitness, gym, workouts, health,Gym near me,Fitness center in Chennai,Best gym chennai,chennai fitness classes,Personal training chennai,Workout classes chennai,Women's gym near me,CrossFit gym chennai,Yoga classes chennai,Affordable gym chennai,24-hour gym chennaiBodybuilding gym chennai,muscle warriors gym chennai,muscle warriors fitness center,muscle warriors workout classes,muscle warriors,gym supplements,protien supplements" />
                <meta name="author" content="Rithish Abinav" />
            </Helmet>

            <Header />

            <main>
                {/* banner */}

                <div className="banner sec">
                    <h1>About Us</h1>
                </div>

                <p className='banner_content'>Welcome to <span>Muscle Warriors</span> Gym, where passion meets sweat and dedication transforms into results. We're not just a gym; we're a community of fitness enthusiasts, dream chasers, and goal conquerors.</p>

                {/* our story */}
                <div className="our_story sec">
                    <h3 className='heading'>Our <span>Story</span></h3>

                    <section>
                        <div className="left_section">
                            <p>At <span>Muscle Warriors</span> Gym, we believe that every drop of sweat is a step toward your aspirations. Founded in 2020, our journey began with a simple vision: to create a fitness haven that inspires greatness and empowers individuals to become their strongest selves.
                                <br /><br />
                                Our journey began with a spark, a shared vision among a group of fitness aficionados who craved more than just a place to work out. In <span>2020</span>, we ignited the flame that would become Muscle Warriors Gym. Our story isn't just about sets and reps; it's about inspiring individuals to unearth their inner strength and rewrite their limits.
                                <br /><br />
                                As we huddled together, we realized that fitness is a journey of self-discovery. Our founders envisioned a space where every drop of sweat would symbolize a step towards our aspirations, where every grunt would echo the determination to conquer the seemingly impossible.
                            </p>
                        </div>
                        <div className="right_section">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/ourstory.webp')} width='100%' />
                        </div>
                    </section>
                </div>


                {/* Unleash Your Potential */}

                <div className="unleash sec">
                    <h3 className='heading'>Unleash Your Potential</h3>
                    <p>Our gym isn't just a collection of weights and machines; it's a realm of possibilities. We're not just about reps and sets; we're about breaking barriers and shattering limits. Muscle Warriors Gym is where you harness your potential, guided by certified trainers who are passionate about your success.</p>

                    <div className="_badges">
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym1.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym2.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym3.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym4.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym5.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym6.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym7.jpg')} />
                        </div>
                        <div className="_badge">
                            <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/gym8.jpg')} />
                        </div>
                    </div>

                </div>

                {/* What Sets Us Apart ? */}

                <div className="what_set sec">
                    <h3 className='heading'>What Sets Us Apart ?</h3>
                    <section>
                        <p><span>Passion-Driven</span> : Our trainers don't just count repetitions; they ignite your motivation. Passion is the heartbeat of Muscle Warriors Gym.</p>
                        <p><span>Community Spirit</span> :  We're not just a gym; we're a family that supports, encourages, and celebrates each other's victories.</p>
                        <p><span>Cutting-Edge Facilities</span> : Our state-of-the-art gym is equipped with the latest equipment and technology, ensuring your fitness journey is innovative and exciting.</p>
                        <p><span>Customized Equipment</span> : What truly sets us apart is our commitment to your unique fitness journey. We offer customized equipment that adapts to your needs, making your workouts flexible, effective, and tailored just for you.</p>
                        <p><span>Personalized Approach</span> : Your goals are unique, and so is your journey. Our trainers tailor workouts that align with your aspirations and preferences.</p>
                    </section>
                </div>

                {/* Your Transformation Awaits */}
                <div className="transformation sec">
                    <h3 className='heading'>Your Transformation Awaits</h3>
                    <p>At <span>Muscle Warriors</span> Gym, we're more than just barbells and treadmills. We're a sanctuary of transformation, a canvas of dreams turned into reality. Whether you're just starting your fitness journey or you're a seasoned athlete, we're here to guide you, challenge you, and witness your evolution.</p>
                    <div id="carouselExampleIndicators" class="carousel slide">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div className="_badges">
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/transformation1.webp')} />
                                    </div>
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/transformation2.webp')} />
                                    </div>
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/transformation3.webp')} />
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item ">
                                <div className="_badges">
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/transformation4.webp')} />
                                    </div>
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/transformation5.webp')} />
                                    </div>
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/lt (1).jpg')} />
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div className="_badges">
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/lt (5).jpg')} />        </div>
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/lt (3).jpg')} />        </div>
                                    <div className="_badge">
                                        <img loading='lazy' alt='Muscle Warriors' src={require('../Assets/lt (4).jpg')} />        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Join the Movement */}
                <div className="join sec">
                    <h3 className='heading'>Join the Movement</h3>
                    <p>Step into <span>Muscle Warriors</span> Gym and be part of a movement that's not just about physical transformation, but about nurturing a mindset of resilience, determination, and victory.
                        <br /><br />
                        Join us today, and let's redefine your limits together.
                    </p>
                </div>

            </main>
            <Footer />
        </div>
    )
}

