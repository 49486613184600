import React, { useState } from 'react';

function Calculator() {
    const [gender, setGender] = useState('male');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [age, setAge] = useState('');
    const [activityLevel, setActivityLevel] = useState('sedentary');
    const [bmr, setBMR] = useState(0);
    const [tdee, setTDEE] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [error, setError] = useState('');

    const [weightLossCaloriesValue, setWeightLossCaloriesValue] = useState(0);
    const [mildWeightLossCaloriesValue, setMildWeightLossCaloriesValue] = useState(0);
    const [extremeWeightLossCaloriesValue, setExtremeWeightLossCaloriesValue] = useState(0);
    const [weightGainCaloriesValue, setWeightGainCaloriesValue] = useState(0);
    const [mildWeightGainCaloriesValue, setMildWeightGainCaloriesValue] = useState(0);
    const [extremeWeightGainCaloriesValue, setExtremeWeightGainCaloriesValue] = useState(0);
    const [maintenanceCaloriesValue, setMaintenanceCaloriesValue] = useState(0);

    const calculateAll = () => {
        if (!age || !weight || !height) {
            setError('Please fill out all the fields.');
            setShowResults(false);
            return;
        }

        setError('');

        let bmrValue = 0;
        let tdeeValue = 0;
        let weightLossCaloriesValue = 0;
        let mildWeightLossCaloriesValue = 0;
        let extremeWeightLossCaloriesValue = 0;
        let weightGainCaloriesValue = 0;
        let mildWeightGainCaloriesValue = 0;
        let extremeWeightGainCaloriesValue = 0;

        if (gender === 'male') {
            bmrValue = Math.round(88.362 + 13.397 * weight + 4.799 * height - 5.677 * age);
        } else if (gender === 'female') {
            bmrValue = Math.round(432.70 + 9.247 * weight + 3.098 * height - 4.330 * age);
        }

        switch (activityLevel) {
            case 'sedentary':
                tdeeValue = Math.round(bmrValue * 1.1459);
                break;
            case 'lightly-active':
                tdeeValue = Math.round(bmrValue * 1.3133);
                break;
            case 'moderately-active':
                tdeeValue = Math.round(bmrValue * 1.399);
                break;
            case 'Active':
                tdeeValue = Math.round(bmrValue * 1.4805);
                break;
            case 'very-active':
                tdeeValue = Math.round(bmrValue * 1.6476);
                break;
            case 'super-active':
                tdeeValue = Math.round(bmrValue * 1.8145);
                break;
            default:
                tdeeValue = Math.round(bmrValue);
        }
        // Calculate percentages based on gender and activity level
        if (gender === 'male') {
            if (activityLevel === 'sedentary') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.73);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.87);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.46);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.27);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.13);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.51);
            } else if (activityLevel === 'lightly-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.77);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.88);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.53);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.23);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.12);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.47);
            } else if (activityLevel === 'moderately-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.78);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.89);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.56);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.22);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.11);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.44);
            } else if (activityLevel === 'Active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.79);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.90);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.58);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.21);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.10);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.42);
            } else if (activityLevel === 'very-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.81);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.91);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.63);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.19);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.09);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.37);
            } else if (activityLevel === 'super-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.83);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.92);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.66);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.17);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.08);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.34);
            }
        } else if (gender === 'female') {
            if (activityLevel === 'sedentary') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.70);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.85);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.40);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.30);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.15);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.60);
            } else if (activityLevel === 'lightly-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.74);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.87);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.47);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.26);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.13);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.56);
            } else if (activityLevel === 'moderately-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.75);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.88);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.51);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.25);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.12);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.49);
            }
            else if (activityLevel === 'Active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.77);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.88);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.53);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.23);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.12);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.47);
            } else if (activityLevel === 'very-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.77);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.88);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.53);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.21);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.10);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.42);
            } else if (activityLevel === 'super-active') {
                weightLossCaloriesValue = Math.round(tdeeValue * 0.81);
                mildWeightLossCaloriesValue = Math.round(tdeeValue * 0.90);
                extremeWeightLossCaloriesValue = Math.round(tdeeValue * 0.62);
                weightGainCaloriesValue = Math.round(tdeeValue * 1.19);
                mildWeightGainCaloriesValue = Math.round(tdeeValue * 1.10);
                extremeWeightGainCaloriesValue = Math.round(tdeeValue * 1.38);
            }
        }

        setBMR(bmrValue);
        setTDEE(tdeeValue);
        setWeightLossCaloriesValue(weightLossCaloriesValue);
        setMildWeightLossCaloriesValue(mildWeightLossCaloriesValue);
        setExtremeWeightLossCaloriesValue(extremeWeightLossCaloriesValue);
        setWeightGainCaloriesValue(weightGainCaloriesValue);
        setMildWeightGainCaloriesValue(mildWeightGainCaloriesValue);
        setExtremeWeightGainCaloriesValue(extremeWeightGainCaloriesValue);
        setMaintenanceCaloriesValue(tdeeValue);

        setShowResults(true);
    };

    return (
        <div className='calculatorcomp'>
            <div className="calciform">

                <div>
                    <label>
                        Gender:  </label>
                    <select value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>

                </div>
                <div>
                    <label>
                        Weight (kg):    </label>
                    <input
                        type="number"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                    />

                </div>
                <div>
                    <label>
                        Height (cm):     </label>
                    <input
                        type="number"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                    />

                </div>
                <div>
                    <label>
                        Age:  </label>
                    <input type="number" value={age} onChange={(e) => setAge(e.target.value)} />

                </div>
                <div>
                    <label>
                        Activity : </label>
                    <select value={activityLevel} onChange={(e) => setActivityLevel(e.target.value)}>
                        <option value="sedentary">Sedentary (little or no exercise)</option>
                        <option value="lightly-active">Lightly active (light exercise or sports 1-3 days a week)</option>
                        <option value="moderately-active">Moderately active (moderate exercise or sports 3-5 days a week)</option>
                        <option value="Active">Active (Daily exercise or Intense exercise 3-4 times/week)</option>
                        <option value="very-active">Very active (hard exercise or sports 6-7 days a week)</option>
                        <option value="super-active">Super active (very hard exercise, physical job, or training)</option>
                    </select>

                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button className='red_btn' onClick={calculateAll}>Calculate</button>

            </div>
            {showResults && (
        //         <div className='result'>
        //             <p><span>BMR:</span> {bmr} calories/day</p>
        //  <p><span>TDEE:</span> {tdee} calories/day</p>
        //             <p><span>Maintenance Calories :</span> {maintenanceCaloriesValue} calories/day</p>

        //             <p><span>Weight Loss :</span> {weightLossCaloriesValue} calories/day</p>
        //             <p><span>Mild Weight Loss :</span> {mildWeightLossCaloriesValue} calories/day</p>
        //             <p><span>Extreme Weight Loss :</span> {extremeWeightLossCaloriesValue} calories/day</p>
        //             <p><span>Weight Gain :</span> {weightGainCaloriesValue} calories/day</p>
        //             <p><span>Mild Weight Gain :</span> {mildWeightGainCaloriesValue} calories/day</p>
        //             <p><span>Extreme Weight Gain :</span> {extremeWeightGainCaloriesValue} calories/day</p>
        //         </div>
<div className='result'>
                <table>
                    <tr>
                        <td>Maintenance Calories</td> <td>{maintenanceCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                    <tr>
                        <td>Weight Loss</td> <td>{weightLossCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                    <tr>
                        <td>Mild Weight Loss</td>   <td>{mildWeightLossCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                    <tr>
                        <td>Extreme Weight Loss</td>  <td>{extremeWeightLossCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                    <tr>
                        <td>Weight Gain</td>  <td>{weightGainCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                    <tr>
                        <td>Mild Weight Gain</td>  <td> {mildWeightGainCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                    <tr>
                        <td>Extreme Weight Gain</td>  <td>{extremeWeightGainCaloriesValue} calories/day <span>(Approx)</span></td>
                    </tr>
                </table>
                </div>


            )}
        </div>
    );
}

export default Calculator;
